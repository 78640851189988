<template>
  <section class="rental-investment">
    <section class="hero">
      <div class="hero-container">
        <h2>Configurez votre projet d’investissement locatif</h2>
      </div>
      <configurator-search/>
    </section>

    <div class="grid-container">
      <div class="intro">
        <div class="intro-img">
          <img
            src="@/assets/img/pages/rental-invest-2.jpg"
            alt="Investir dans la maison individuelle : un placement sûr et de long terme"
          />
        </div>
        <div class="intro-text">
          <h1>Investir dans la maison individuelle : un placement sûr et de long terme</h1>
          <ul>
            <li>
              <p>75% des français rêvent de vivre dans une maison individuelle.</p>
            </li>
            <li>
              <p>
                Plus avantageux que l’appartement (pas de frais de notaire sur la construction, pas
                de frais de copropriété).
              </p>
            </li>
            <li>
              <p>
                Une offre structurellement inférieure à une demande soutenue (seulement 20% de
                l’offre locative est constituée de maisons individuelles vs 80% d’appartements en
                collectif).
              </p>
            </li>
            <li>
              <p>
                Les risques de décote sont limités voire nuls. Attendez-vous plutôt à une plus-value
                !
              </p>
            </li>
            <li>
              <p>
                Des locataires stables, en famille, qui s’approprient le bien et qui
                l’entretiennent.
              </p>
            </li>
          </ul>
          <div class="more-about">
            <p>En savoir plus :</p>
            <router-link to="/projet-construction-maison/faire-construire-une-maison-pour-la-louer-quels-avantages">Faire construire une maison pour la louer : quels avantages ?</router-link>
            <router-link to="/projet-construction-maison/construire-pour-louer-en-meuble">Construire pour louer en meublé : pourquoi c’est rentable ?</router-link>
          </div>
        </div>
      </div>

      <div class="tax-opportunity">
        <h3>Bénéficier de belles opportunités fiscales et patrimoniales</h3>
        <div class="tax-opportunity-grid">
          <div class="card-city">
            <figure>
              <img src="@/assets/img/pages/rental-invest-3.jpg" alt="Préparer sa retraite" />
              <figcaption>
                <h3>Préparer sa retraite</h3>
              </figcaption>
            </figure>
          </div>
          <div class="card-city">
            <figure>
              <img src="@/assets/img/pages/rental-invest-4.jpg" alt="Compléter ses revenus" />
              <figcaption>
                <h3>Compléter ses revenus</h3>
              </figcaption>
            </figure>
          </div>
          <div class="card-city">
            <figure>
              <img
                src="@/assets/img/pages/rental-invest-5.jpg"
                alt="Se constituer un patrimoine"
              />
              <figcaption>
                <h3>Se constituer un patrimoine</h3>
              </figcaption>
            </figure>
          </div>
          <div class="card-city">
            <figure>
              <img src="@/assets/img/pages/rental-invest-6.jpg" alt="Réduire ses impôts" />
              <figcaption>
                <h3>Réduire ses impôts</h3>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>

    <div class="invest">
      <div class="grid-container">
        <div class="grid-x invest-flex">
          <div class="cell medium-6 invest-text">
            <h2>
              Choisir de faire construire avec Maisons Alysia, c'est miser sur la sécurité et la
              performance
            </h2>
            <ul>
              <li>
                <p>
                  <strong>Un concept innovant</strong>, validé avec un réseau d'agences en plein
                  développement. Un tarif parmi les plus compétitifs constaté sur le marché.
                </p>
              </li>
              <li>
                <p>
                  <strong>Un rapport qualité/prix</strong> permis par un process de construction
                  rationalisé, en circuit court.
                </p>
              </li>
              <li>
                <p>
                  <strong>La qualité au top</strong> avec un mode constructif, des matériaux et des
                  équipements prémium.
                </p>
              </li>
              <li>
                <p>
                  <strong>Un modèle d'agence inédit</strong> : un interlocuteur qui est le gérant ET
                  le conducteur de travaux.
                  <strong>Une parfaite maîtrise de l'ingénierie fiscale</strong> et juridique du
                  projet.
                </p>
              </li>
              <li>
                <p><strong>Un planning optimisé</strong> et un chantier coordonné.</p>
              </li>
              <li>
                <p>
                  <strong>Une maison clés en main</strong>, du montage financier à la livraison.
                </p>
              </li>
            </ul>
          </div>
          <div class="cell medium-6 invest-img">
            <img src="@/assets/img/pages/invest.png" alt="Investir" />
            <img src="@/assets/img/pages/invest-logo.png" alt="Invest Logo" />
          </div>
        </div>

        <div class="invest-homes">
          <img src="@/assets/img/pages/rental-invest-7.jpg" alt="Modèle de maison Alysia" />
          <img src="@/assets/img/pages/rental-invest-8.jpg" alt="Modèle de maison Alysia" />
          <img src="@/assets/img/pages/rental-invest-9.jpg" alt="Modèle de maison Alysia" />
          <img src="@/assets/img/pages/rental-invest-10.jpg" alt="Modèle de maison Alysia" />
        </div>
      </div>
    </div>

    <div class="grid-container tax-exemption">
      <div class="grid-x tax-exemption-card">
        <div class="cell medium-6">
          <img src="@/assets/img/pages/businessman-making-ok-sign.png" alt="Homme d'affaire souriant" />
        </div>
        <div class="cell medium-6">
          <h2>Besoin d’un chiffrage rapidement ?</h2>
          <p>
            Evaluez la rentabilité de votre projet en obtenant immédiatement votre chiffrage grâce à
            notre configurateur unique en France. C'est gratuit, anonyme et sans engagement !
          </p>
          <router-link :to="{ name: 'home', hash: '#hero' }" class="link-button">
            <app-button theme="primary" hasArrow>Commencer</app-button>
          </router-link>
          <router-link to="/outils/calculette-simulation-credit">En savoir plus</router-link>
        </div>
      </div>
      <div class="tax-exemption-title">
        <h2>Combiner investissement immobilier et défiscalisation avec la Loi Pinel</h2>
      </div>
      <div class="tax-exemption-content">
        <div class="grid-x tax-exemption-text">
          <div class="cell medium-6">
            <h3>Le dispositif</h3>
            <p>
              Réaliser un investissement avec la Loi Pinel permet de disposer à la fois d’un revenu
              locatif et d’avantages fiscaux. Cette formule donne droit à une réduction d’impôt sur
              le revenu de 12 à 21% du prix du bien et pouvant atteindre 6 000 € par an. Le
              législateur a souhaité stimuler la construction de logements, favoriser
              l’investissement dans la pierre et étendre l’offre de logements locatifs pour les
              ménages aux revenus intermédiaires.
            </p>
          </div>
          <div class="cell medium-6">
            <h3>Le principe</h3>
            <p>
              La seule contrainte est de faire construire dans le seul but de dédier le logement à
              la location avant 1 an après l’achèvement des travaux et pour un engagement d’une
              durée de 6, 9 ou 12 ans. Il faudra alors veiller à observer les critères permettant
              d’offrir un bien facile à louer pour vous assurer une excellente rentabilité :
              localisation, qualité et calme du quartier, proximité avec les commodités et
              infrastructures de la commune… Vous pouvez également le louer à une personne de votre
              famille ou de votre entourage tout en bénéficiant des ces avantages.
            </p>
          </div>
        </div>
        <div class="grid-x tax-exemption-text">
          <div class="cell medium-6">
            <h3>Votre expert</h3>
            <p>
              Le choix de la situation, celui du modèle de maison en fonction du profil du locataire
              recherché, la rentabilité, le montage financier et ses conditions sont à étudier avec
              votre conseiller Maisons Alysia, véritable spécialiste du territoire local et de
              l’investissement locatif.
            </p>
          </div>
          <div class="cell medium-6">
            <h3>Et après…</h3>
            <p>
              Au terme du contrat fiscal, plusieurs solutions s’offrent à vous : la revente pour
              obtenir une plus-value, en faire profiter vos enfants, continuer à le louer pour vous
              assurer un complément de revenus pour votre retraite ou financer les études des
              enfants… Choisir la sécurité et voir loin !
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ConfiguratorSearch from '../../components/general/ConfiguratorSearch.vue';

export default {
  name: 'project-house-budget',
  components: {
    ConfiguratorSearch,
  },
  data() {
    return {};
  },
};
</script>

<style lang="sass">
.rental-investment
  padding: 0 0 9rem
  background: $medium-bg

  ul
    padding-inline-start: 2rem

    li
      list-style-image: url("../../assets/img/arrow-blue.svg")

      + li
        margin-top: 1rem

  .hero
    @include hero
    background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/rental-invest-1.jpg)
    padding: 0 2rem
    width: initial
    @media (max-width: 768px)
      min-height: 650px

    .hero-container
      max-width: 950px
      width: 100%

      h2
        color: $white
        text-align: center
        margin-bottom: 2.5rem

    .configurator-search
      width: 65vw
      min-width: 300px
      margin: 0 auto
      li
        margin: initial
      @media (max-width: 1024px)
        width: 100%

  .intro
    display: flex
    flex-direction: row
    margin-top: 6rem

    &-img
      width: 100%

      img
        width: 100%
        object-fit: cover

    &-text
      display: flex
      flex-direction: column
      margin-left: 4.5rem

      h1
        margin-bottom: 2rem

    .more-about
      display: flex
      flex-direction: column
      margin-top: 2rem

      p
        font-weight: 700
        margin-bottom: 0.5rem

      a
        @include hyperlink
        + a
          margin-top: 0.5rem

  .tax-opportunity
    margin: 4.5rem 0 6.5rem 0

    h3
      text-align: center
      margin-bottom: 3rem

    &-grid
      display: grid
      grid-template-columns: repeat(4, 1fr)
      grid-template-rows: 1fr
      grid-column-gap: 1rem

    .card-city
      @include card-city

      figure
        min-width: auto

      img
        filter: brightness(50%)

  .invest
    background-color: $colored-bg

    > div
      padding-top: 8rem
      padding-bottom: 8rem

    &-text
      h2
        color: $white

      p
        color: $line

      ul
        margin-top: 2rem

    &-img
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center

    &-homes
      display: grid;
      grid-template-columns: repeat(2, 1fr)
      grid-template-rows: repeat(2, 1fr)
      grid-column-gap: 1rem
      grid-row-gap: 1rem
      margin-top: 8rem

      img
        width: 100%
        object-fit: cover
        border-radius: 8px

  .tax-exemption
    margin-top: 4.5rem

    .link-button
      text-decoration: none

    &-card
      align-items: center
      background-color: $white
      border-radius: $global-border-radius
      box-shadow: $drop-shadow
      padding: 3rem 7rem

      > div:last-child
        display: flex
        flex-direction: column
        align-items: center
        text-align: center

        h2, p
          margin-bottom: 2rem

        button
          margin-bottom: 1rem

    &-title
      margin: 7rem 0 6rem 0

      h2
        text-align: center

    &-content
      margin: 0 6rem

      > div:last-child
        margin-top: 4rem

      h3
        margin-bottom: 1.5rem

  @media (max-width: 768px)
    padding: 0 0 5rem

    .intro
      flex-direction: column-reverse
      margin-top: 4.5rem

      &-text
        margin: 0 0 2rem 0

    .tax-opportunity
      &-grid
        grid-template-columns: 1fr
        grid-template-rows: repeat(4, 1fr)
        grid-column-gap: 0
        grid-row-gap: 1rem

      .card-city
        width: 100%

    .invest
      &-flex
        flex-direction: column-reverse

      &-text
        margin-top: 4rem

      &-img
        > img
          object-fit: cover
          width: 100%

          &:last-child
            width: 70%

      &-homes
        grid-template-columns: 1fr
        grid-template-rows: repeat(4, 1fr)
        margin-top: 5rem

    .tax-exemption
      &-card
        padding: 2rem 1rem
        margin: 0 1rem

        > div:last-child
          margin-top: 2rem

        img
          object-fit: cover
          width: 100%

      &-title
        margin: 4.5rem 0 3rem 0

      &-content
        margin: 0

        > div:last-child
          margin-top: 2.5rem

      &-text
        > div + div
          margin-top: 2.5rem
</style>
